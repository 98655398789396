.EducationSectionWrapper {
    position: relative;
    top: 35vh;
    width: 1400px;
    height: 100px;
    margin: auto;
    transition: all 1s ease;
    /* background-color: bisque; */
    cursor: default;
    transform: scale(1) rotate(0deg);
}

.TimeLineFeatureSuperWrapper {
    width: 1400px;
    height: 100px;
    border-left: 1px solid rgb(138, 138, 138);
    border-right: 1px solid rgb(138, 138, 138);
    /* background-color: rgb(89, 89, 89); */
    transition: all 1s ease;
}

.TimeLineWrapper {
    display: flex;
    position: relative;
    top: 49px;
    width: 1400px;
    height: 1px;
    background-color: rgb(138, 138, 138);
    transition: all 1s ease;
}

.TimeLineBeginningText {
    position: absolute;
    top: -72px;
    left: -28px;
    font-size: 14px;
    transition: all 1s ease;
}

.TimeLineInBetweenYearWrapper {
    position: relative;
    top: -40px;
    width: 1px;
    height: 40px;
    margin-left: 20%;
    background-color: rgb(138, 138, 138);
    transition: all 1s ease;
}

.TimeLineInBetweenYearText {
    position: relative;
    top: -20px;
    left: -14px;
    font-size: 14px;
    /* background-color: tomato; */
    color: thistle;
    transition: all 1s ease;
}

.TimeLinePresentText {
    position: absolute;
    top: -72px;
    left: 1372px;
    font-size: 14px;
    transition: all 1s ease;
}

.TimeLineNodesWrapper {
    display: flex;
    position: absolute;
    top: -10px;
    width: 1400px;
    height: 20px;
    /* background-color: rgb(255, 255, 255); */
    transition: all 1s ease;
}




@media(max-width: 1665px) {
    .EducationSectionWrapper {
        transform: scale(0.8) rotate(0deg);
    }
}

/* Here we .6 all attributes and maintain the scale to 1 */
@media(max-width:1400px) {
    .EducationSectionWrapper {
        width: 840px;
        transform: scale(1) rotate(0deg);

    }

    .TimeLineFeatureSuperWrapper {
        width: 840px;
        height: 60px;
    }

    .TimeLineWrapper {
        width: 840px;
    }

    .TimeLineBeginningText {
        position: absolute;
        top: -72px;
        left: -26px;
        font-size: 12px;
    }

    .TimeLineInBetweenYearText {
        top: -20px;
        left: -12px;
        font-size: 12px;
    }

    .TimeLinePresentText {
        position: absolute;
        top: -72px;
        left: 820px;
        font-size: 12px;
    }

    .TimeLineNodesWrapper {
        display: flex;
        position: absolute;
        top: -6px;
        width: 840px;
        height: 12px;
    }

}


@media(max-width:1000px) {
    .EducationSectionWrapper {
        width: 40px;
        top: 100px;
        margin-bottom: 1000px;
        transform: scale(1) rotate(90deg);
    }

    .TimeLineBeginningText {
        top: -80px;
        left: -12px;
        transform: rotate(-90deg);
    }

    .TimeLineInBetweenYearText {
        left: 1px;
        transform: rotate(-90deg);
    }

    .TimeLinePresentText {
        top: -80px;
        left: 830px;
        transform: rotate(-90deg);
    }

}