.ProjectTechNugget{
    color: rgb(100,100,100);
    background-color: rgb(250, 247, 208);
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
    height: 18px;
    margin-top: 5px;
    margin-right: 8px;
    transition: all .2s ease;
    text-align: center;
    border-radius: 3px;
    letter-spacing: 1px;
    transition: all .4s linear;
    cursor: default;
}
@media(max-width: 1150px){
    .ProjectTechNugget{
    font-size: 11px;
    padding-left: 3px;
    padding-right: 3px;
    height: 16px;
    margin-right: 7px;
}
}