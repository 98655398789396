.ModalSuperWrapper{
    display: none;
    z-index: 98;
    overflow: hidden;
    position: absolute;
    width: 1000px;
    height: 600px;
    left: 0;
    right: 0;
    top: 100px;
    margin-left:auto;
    margin-right: auto;
    border-radius: 10px;
    transition: all .4s ease;
    /* background-color: rgba(33, 30, 38, 0.912); */
}
.ModalHeaderSuperWrapper{
    width: 100%;
    height: 3%;
    opacity: .50;
    /* background-color: rgba(16, 162, 99, 0.912); */
    background-image:linear-gradient(90deg,rgb(255, 0, 0), rgb(0, 162, 255));
}
.ModalTitle{
    color: rgb(255, 255, 255);
    text-align: center;
    border-left: 1px solid rgba(255, 0, 0, 0.494);
    border-right: 1px solid rgba(0, 162, 255, 0.501);
    border-bottom: 1px solid rgb(0, 0, 0);
    background-color: rgba(8, 4, 10, 0.912);
}
.ModalBodySuperWrapper{
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 94%;
    background-color: rgba(8, 4, 10, 0.912);
    /* padding-right: 100px; */
    animation-name: ModalOpen;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}
.ModalBodyWrapper{
    width: 800px;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    /* border-left: 1px solid red;
    border-right: 1px solid rgb(0, 153, 255); */
    letter-spacing: 2px;
    font-size: 14px;
    color: rgb(227, 228, 213);
    /* background-color: rgb(22, 13, 29); */
}
.ModalFooterSuperWrapper{
    width: 100%;
    height: 3%;
    opacity: .01;
    background-image:linear-gradient(90deg,rgb(255, 0, 0), rgb(0, 162, 255));
    animation-name: Fade;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
}
.ActiveModal {
    display: block;
}
@keyframes ModalOpen{
    0%{
        height: 0%;
        width: 0%;
    }
    50%{
        height: 94%;
        width: 0%;
    }
    100%{
        width: 100%;
    }
}
@keyframes Fade{
    0% {
        opacity: .5;
    }
    100%{
        opacity: 0;
    }
}
/* __________________________________________________________________ */
/*___________________________1150_______________________________*/
/* __________________________________________________________________ */
@media(max-width: 1150px){
    .ModalSuperWrapper{
        width: 600px; 
    }
    .ModalBodyWrapper{
        width: 500px;
        padding: 15px;
    }
}
@media(max-width: 750px){
    .ModalSuperWrapper{
        width: 400px; 
    }
    .ModalBodyWrapper{
        width: 340px;
        padding: 15px;
        font-size: 11px;
    }
}
@media(max-width: 510px){
    .ModalSuperWrapper{
        width: 300px; 
    }
    .ModalBodyWrapper{
        width: 270px;
        padding: 4px;
    }
}

@media(max-height: 750px){
    .ModalSuperWrapper{
        height: 500px; 
        top: 80px;
    }
}
@media(max-height: 650px){
    .ModalSuperWrapper{
        height: 400px; 
        top: 60px;
    }
}
@media(max-height: 510px){
    .ModalSuperWrapper{
        height: 300px; 
        top: 50px;
    }
}