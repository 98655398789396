.ProjectSectionWrapper{
    display: flex;
    flex-direction: row;
    margin-left: 125px;
    margin-top: 50px;
    /* background-color: rgb(50, 54, 54); */
    width: 900px;
    padding: 20px;
    padding-top: 50px;
    border-top: 1px solid;
    border-image-source: linear-gradient(90deg,  rgb(12, 10, 15),rgb(41, 34, 49), rgb(12, 10, 15));
    border-image-slice: 1;
    transition: all .4s ease;
}
.ProjectDescriptionWrapper{
    color: rgb(212, 212, 212);
    font-size: 14px;
    position: relative;
    /* background-color: rgb(41, 41, 41); */
    height: 354px;
    width: 480px;
    transition: all .4s ease;
}
.ProjectDescription{
    /* background-color: rgb(51, 51, 83); */
    height: 150px;
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    letter-spacing: 1px;
    cursor: default;
    transition: all .4s ease;

}
.LinkElement{
    margin: none;
    position: relative;
    bottom: 8px;
    border-style: none;
    outline-style: none;
    text-decoration: none;
    color: white;
    height: 40px;
    width: 110px;
    /* background-color: lawngreen; */
    transition: all .4s ease;
}
.ProjectButtonOne{
    position: relative;
    background-color: rgb(250, 247, 208);
    height: 40px;
    width: 110px;
    border-radius: 5px;
    margin: auto;
    bottom: 75px;
    color: rgb(12, 10, 15);
    cursor: pointer;
    transition: all .4s ease;
}
.ProjectButtonTwo{
    position: relative;
    /* background-color: rgb(42, 39, 46); */
    height: 38px;
    width: 108px;
    border-radius: 5px;
    margin: auto;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    transition: all .3s ease;
}
.ProjectButtonTwo:hover{
    background-color: rgb(250, 247, 208);
    color: black;
    border: 1px solid  rgb(250, 247, 208);
}
.ProjectButtonText{
    margin: auto;
    position: relative;
    top: 6px;
    /* background-color: red; */
    width: 85%;
    font-size: 18px;
    text-align: center;
    letter-spacing: 2px;
    transition: all .4s ease;
    
}
.ProjectDescriptionButtons{
    display: flex;
    flex-direction: row;
    /* background-color: rgb(91, 110, 110); */
    height: 174px;
    width: 300px;
    transition: all .4s ease;
}
.ProjectCardWrapper{
    margin-top: 20px;
    /* background-color: rgb(255, 0, 0); */
    width: 250px;
    height: 450px;
    margin: auto;
    margin-right: 150px;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.4);
    border: 1px solid;
    border-image-source: linear-gradient(100deg, rgb(40, 30, 49),transparent, transparent);
    border-image-slice: 1;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    transition: all .4s ease;
}
.ProjectPicWrapper{
    z-index: 98;
    background-image:linear-gradient(90deg,rgb(255, 0, 0), rgb(0, 162, 255));
    /* background-color: rgb(27, 27, 27); */
    width: 100%;
    height: 225px;
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg,rgb(255, 0, 0), rgb(0, 162, 255));
    border-image-slice: 1;
    transition: all .4s ease;
}
.ProjectPic{
    position: relative;
    background-color: rgb(149, 142, 184);
    /* background-image: url(/src/BeatBoxResponsive.png); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 150%;
    height: 90%;
    top: 5%;
    left: 3%;
    border: 1px solid;
    border-image-source: linear-gradient(110deg, rgb(170, 170, 170),transparent, rgb(12, 10, 15));
    border-image-slice: 1;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: inset 0px 0px 30px rgb(12, 10, 15);
    transition: all .4s ease;
}
.ProjectPic:hover{
    /* box-shadow: inset 0px 0px 150px rgb(12, 10, 15); */
    background-color:rgb(250, 247, 208);
}
.ProjectMidDesign{
    position: relative;
    z-index: 31;
    width: 60px;
    height: 60px;
    bottom: 31px;
    left: 90px;
    transition: all .4s ease;
}
.ProjectMidDesignTop{
    height: 30px;
    width: 60px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid rgb(12, 10, 15);
    
}
.ProjectMidDesignBottom{
    height: 20px;
    width: 60px;
    border-right: 1px solid rgb(89, 100, 124);
    border-left: 1px solid rgb(167, 89, 108);
    text-align: center;
}
.ProjectLinesIcon{
    color: rgb(236, 236, 236);
}
.ProjectTitleWrapper{
    position: relative;
    background-image: linear-gradient(90deg, rgb(250, 247, 208), rgb(250, 247, 208), rgb(250, 247, 208), transparent);
    width: 100%;
    height: 50px;
    bottom: 20px;
    /* border-right: 1px solid rgb(17, 15, 19); */
    /* border-bottom-right-radius: 15px;
    border-top-left-radius: 15px; */
    transition: all .5s ease;
    /* box-shadow: inset 0px 0px 3px rgb(12, 10, 15); */
    transition: all .4s ease;
}
.ProjectTitleWrapper:hover{
    width: 900px;
}
.ProjectTitle{
    text-align: center;
    /* background-color: teal; */
    width: 100%;
    height: 25px;
    font-size: 22px;
    letter-spacing: 4px;
    color: rgb(20, 15, 24);
    transition: all .4s ease;
}
.ProjectShortDescription{
    text-align: center;
    font-size: 12px;
    letter-spacing: 2px;
    /* background-color: rgb(19, 58, 116); */
    width: 100%;
    height: 25px;
    color: rgb(100, 100, 100);
    transition: all .4s ease;
}
.ProjectTechUsedWrapper{
    overflow-x: hidden;
    z-index: 29;
    position: absolute;
    float: right;
    /* background-color: rgba(226, 215, 166, 0.219); */
    width: 100%;
    height: 70px;
    /* border-bottom: 1px solid;
    border-image-source: linear-gradient(90deg, transparent, transparent,rgb(41, 34, 49), rgb(12, 10, 15));
    border-image-slice: 1; */
}
.ProjectTechUsed{
    position: relative;
    float: right;
    margin-top: 5px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
    /* background-color: rgb(63, 46, 59); */
    width: auto;
}

.ProjectFooter{
    z-index: 30;
    position: relative;
    bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 65px;
    background-color: rgb(12, 10, 15);
    cursor: default;
    transition: background-color 1s ease;
}
.ProjectFooterSection{
    /* background-color: rgb(143, 143, 143); */
    width: 80px;
    height: 60px;
    margin: auto;
    text-align: center;
    letter-spacing: 1px;
    border-top: 1px solid;
    border-image: linear-gradient(90deg,rgb(12, 10, 15),  rgb(29, 22, 36), rgb(12, 10, 15));
    border-image-slice: 1;
    color: rgb(236, 236, 236);
    font-size: 14px;
    margin-top: 10px;
    
}
.ProjectFooterTitle{
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgb(12, 10, 15),  rgb(40, 30, 49),rgb(12, 10, 15));
    border-image-slice: 1;
}
.ProjectFooterDesc{
    font-size: 12px;
    color: rgb(250, 247, 208);
    margin-top: 5px;
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgb(12, 10, 15), rgb(67, 51, 82),rgb(12, 10, 15));
    border-image-slice: 1;
}
/* _______________________________________________________________________________________________________________ */
/*  */
/* _______________________________________________________________________________________________________________ */
@media(max-width:1150px){
    .ProjectSectionWrapper{
    /* background-color: rgb(50, 54, 54); */
    width: 750px;
    margin-left: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0px;
    }  
    .ProjectDescriptionWrapper{
    font-size: 13px;
    /* background-color: rgb(41, 41, 41); */
    width: 450px;
}
.ProjectDescription{
    /* background-color: rgb(51, 51, 83); */
    height: 130px;
    padding: 0px;
    padding-top: 15px;
}
.LinkElement{
    bottom: -10px;
    height: 28px;
    width: 93px;
    /* background-color: lawngreen; */
}
.ProjectButtonOne{
    background-color: rgb(250, 247, 208);
    height: 30px;
    width: 95px;
    bottom: 62px;
}
.ProjectButtonTwo{
    /* background-color: rgb(42, 39, 46); */
    height: 28px;
    width: 93px;
}
.ProjectButtonText{
    top: 3px;
    /* background-color: red; */
    font-size: 15px;
}
.ProjectDescriptionButtons{
    /* background-color: rgb(91, 110, 110); */
    width: 250px;
}
.ProjectCardWrapper{
    width: 200px;
    height: 370px;
    margin-right: 120px; 
}
.ProjectPicWrapper{
    height: 180px; 
}
.ProjectMidDesign{
    left: 70px;
}
.ProjectTitleWrapper{
    height: 40px;
}
.ProjectTitle{
    height: 22px;
    font-size: 20px;
    letter-spacing: 3px; 
}
.ProjectShortDescription{
    padding-top: 1px;
    font-size: 11px; 
    height: 18px;
}
.ProjectFooterSection{
    /* background-color: rgb(143, 143, 143); */
    width:80px; 
    font-size: 12px; 
}
.ProjectFooterDesc{
    font-size: 11px;
    margin-top: 4px;
}
}
/*  */
/*-------------------------------------------------------------------------------------------------------------------------------------*/
/*  */
@media(max-width:845px){
 .ProjectSectionWrapper{
    width: 400px;
    margin-left: 60px;
    flex-direction: column;
    }  
.ProjectDescriptionWrapper{
    margin-left: 10px;
    font-size: 13px;
    width: 300px;
    height: 100px;
}
.ProjectDescription{
    display: none;
}
.LinkElement{
    bottom: -10px;
    height: 32px;
}
.ProjectButtonOne{
    height: 34px;
    bottom: -1px;
}
.ProjectButtonTwo{
    height:32px;  
}
.ProjectButtonText{
    top: 5px;
}
.ProjectDescriptionButtons{
    margin-top: 20px;
    width: 310px;
    height: 50px;
}
.ProjectCardWrapper{
    width: 300px;
    height: 360px; 
    margin: auto;
}
.ProjectPic{
    width: 280px;
}
.ProjectMidDesign{
    left: 120px;
}
}
@media(max-width:600px){
 .ProjectSectionWrapper{
    margin-left: 20px;
    }  
}
/* --------------------------------------------------------------------------------------------------------- */
@media(max-width: 499px){
.ProjectSectionWrapper{
    /* background-color: aquamarine; */
    width: 290px;
    margin-left: 0px;
    flex-direction: column;
}  
.ProjectDescriptionWrapper{
    /* background-color: aqua; */
    margin-left: 10px;
    font-size: 13px;
    width: 290px;
    height: 100px;
}
.ProjectDescription{
    display: none;
}
.LinkElement{
    bottom: -10px;
    height: 32px;
}
.ProjectButtonOne{
    height: 34px;
    bottom: -1px;
}
.ProjectButtonTwo{
    height:32px;  
}
.ProjectButtonText{
    top: 5px;
}
.ProjectDescriptionButtons{
    /* background-color: blue; */
    margin-top: 20px;
    width: 240px;
    height: 50px;
}
.ProjectCardWrapper{
    width: 250px;
    height: 360px; 
    margin: auto;
}
.ProjectPic{
    width: 232px;
}
.ProjectMidDesign{
    left: 95px;
}
}
