.TimeLineNode{
    position: absolute;
    width: 10px;
    height: 20px;
    background-color: rgb(111, 239, 243);
    transition: all 1s ease;
    cursor: pointer;
}
.TimeLineNodeContentDate{
    text-align: center;
    font-size: 18px;
}
.TimeLineNodeContentTitle{
    text-align: center;
    font-size: 16px;
    color: rgb(111, 239, 243);
}
.TimeLineNodeContentDescription{
    text-align: center;
    font-size: 14px;
}
.TimeLineNodeContentWrapper{
    display: none;
    position: relative;
    top: 20px;
    left: -100px;
    width: 200px;
    height: 300px;
    /* background-color: rgba(255, 255, 255, 0.323); */
    transition: all 1s ease;
    transform: scale(1) rotate(0deg);
}

.ActiveNode{
    display: block;
    animation: show .4s ease-in-out;
}
.InactiveNode{
    display: block;
    transform: scale(0) rotate(0deg);
    animation: hide .3s ease-in-out;
}

@media(max-width:1400px){
    .TimeLineNode{
        height: 12px;
    }
    .TimeLineNodeContentDate{
        font-size: 14px;
    }
    .TimeLineNodeContentTitle{
        font-size: 13px;
    }
    .TimeLineNodeContentDescription{
        font-size: 12px;
    }
}
@media(max-width:1000px){
    .TimeLineNodeContentWrapper{
        top: -45px;
        left: 45px;
        width: 150px;
    }
    .ActiveNode{
       animation: showRotate .4s ease-in-out; 
       transform: scale(1) rotate(-90deg);
    }
    .InactiveNode{
        animation: hideRotate .3s ease-in-out;
}
}




@keyframes show {
    0%{
        transform: scale(0) rotate(0deg);
    }
    100%{
        transform: scale(1) rotate(0deg);
    }
}
@keyframes hide{
    0%{
        transform: scale(1) rotate(0deg);
    }
    100%{
        transform: scale(0) rotate(0deg);
    }
}
@keyframes showRotate {
    0%{
        transform: scale(0) rotate(0deg);
    }
    100%{
        transform: scale(1) rotate(-90deg);
    }
}
@keyframes hideRotate {
    0%{
        transform: scale(1) rotate(-90deg);
    }
    100%{
        transform: scale(0) rotate(0deg);
    }
}