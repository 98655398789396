.ReadMoreSuperWrapper{
    cursor: cell;
    position: relative;
    width: fit-content;
    bottom: 15px;
    left: 10px;
    color: grey;
    transition: all .4s ease;
    /* background-color: rgb(84, 84, 84); */
}
@media(max-width:1150px){
    .ReadMoreSuperWrapper{
    cursor: cell;
    position: relative;
    width: fit-content;
    bottom: -8px;
    left: 200px;
}
}
@media(max-width:499px){
    .ReadMoreSuperWrapper{
    left: 100px;
}
}
