.AboutSectionWrapper{
    /* background-color: burlywood; */
    height: 100vh;
    position: relative;
    top: 80px;
    transition: all .4s ease;
}
.AboutTitle{
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 17px;
    transition: all .4s ease;
}
.AboutSkillsWrapper{
    margin: auto;
    width: 600px;
    display: grid;
    grid-template-columns: auto auto;
    transition: all .4s ease;
}
.AboutSkills{
    text-align: center;
    width: 300px;
    /* background-color: rgb(56, 56, 56); */
    color: rgb(194, 232, 255);
    transition: all .4s ease;
}
mark{
    background-color: rgba(194, 232, 255, 0.281);
    color: rgb(255, 255, 255);
    border-radius: 3px;
    transition: all .4s ease;
}
.AboutMessageOne{
    letter-spacing: 1px;
    margin: auto;
    /* background-color: rgb(59, 40, 44); */
    width: 600px;
    padding: 10px;
    color: rgb(227, 228, 213);
    font-size: 15px;
    transition: all .4s ease;
    
}
.AboutWEWrapper{
    display: flex;
    width: 600px;
    margin: auto;
    margin-top: 10px;
    transition: all .4s ease;
    /* background-color: brown; */
}
.AboutWEPosition{
    position: relative;
    top: 6px;
    left: 10px;
    color: rgb(227, 228, 213);
    /* background-color: burlywood; */
    font-size: 14px;
    transition: all .4s ease;
}
.AboutWETime{
    position: relative;
    top: 6px;
    left: 200px;
    /* background-color: burlywood; */
    color: rgb(194, 232, 255);
    font-size: 14px;
    transition: all .4s ease;
}
.greenstarLogo{
    width: 120px;
    height: 35px;
    /* background-color: rgb(255, 9, 9); */
    background-image: url(/src/greenstaarWhite.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: all .4s ease;
}
@media(max-width: 1000px){
    .AboutSkillsWrapper{
    width: 420px;
}
.AboutSkills{
    width: 210px;
    font-size: 15px;
}
.AboutMessageOne{
    width: 400px;
    font-size: 14px;
}
.AboutWEWrapper{
    width: 400px;
}
.AboutWETime{
    left: 20px;
}
}
@media(max-width:600px){
}
@media(max-width: 499px){
    .AboutSkillsWrapper{
    width: 340px;
}
.AboutSkills{
    width: 170px;
    font-size: 11px;
}
.AboutMessageOne{
    width: 300px;
    font-size: 12px;
}
.AboutWEWrapper{
    width: 340px;
}
.AboutWEPosition{
    top: 3px;
    left: 5px;
    font-size: 12px;
}
.AboutWETime{
    top: 3px;
    left: 15px;
    font-size: 12px;
}
.greenstarLogo{
    width: 90px;
    height: 25px;
}
}
@media(max-width:600px){
}