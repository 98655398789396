body{
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgb(12, 10, 15);
  font-family: 'Baloo Tamma 2', cursive;
  color: white;
  transition:  background-color .6s ease
}
::-webkit-scrollbar{
  background: rgb(42, 28, 51);
  width: 10px;
}
::-webkit-scrollbar-thumb{
  background:rgb(80, 74, 87);
}
.SuperWrapper{
  /* background-color: red; */
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
}
.Logo{
  position: absolute;
  z-index: 97;
  top: 15px;
  left: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* border: 1px solid rgb(255, 255, 255); */
  background-image: url(/src/HomePageLogo.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all .3s ease;
  background-color: rgb(40, 28, 53);
}
.Logo:hover{
  border-radius: 0%;
}
.HeaderWrapper{
  position: relative;
  display: flex;
  flex-direction: row;
  /* background-color: rgb(86, 126, 126); */
  justify-content: flex-end;
  padding-right: 10%;
  top: 30px;
  transition: all .6s ease;
}
.HeaderItem{
  /* background-color: aquamarine; */
  z-index: 96;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 2px;
  width: auto;
  padding-left: 35px;
  padding-right: 35px;
  transition: all .6s ease;
}
.HeaderLineThingWrapper{
  display: flex;
  z-index: 96;
  justify-content: flex-end;
  position: relative;
  height: 2px;
  /* background-color: rgb(20, 197, 220); */
  top: 30px;
  padding-right: 10%;
}
.HeaderLineThing{
  /* background-color:rgb(255, 255, 255); */
  height: 1px;
  width: 500px;
  /* border-radius: 1px; */
  outline-style: none;
  border-style: none;
  margin: 0;
  margin-right: 10px;
  transition: all .35s ease;
  background-image: linear-gradient(to right,red ,rgb(0, 162, 255) );
}
.RightHeaderWrapper{
  z-index: 2;
  position: absolute;
  margin-left: 96vw;
  margin-top: 30vh;
  /* background-color: rgb(139, 81, 71); */
  width: 40px;
  height: 140px;
  transition: all .35s ease;
}
i{
  color: white;
  transition: color .3s ease;
}
i:hover{
  color: rgb(92, 92, 92);
}
.GitHubButton{
  /* background-color: thistle; */
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  
}
.LinkedInButton{
  /* background-color: rgb(163, 39, 163); */
  width: 30px;
  height: 30px;
  margin: auto;
  margin-top: 60px;
  
}
.MidContent{
  position: relative;
  /* background-color: cadetblue; */
  width: 800px; 
  height: 220px;
  margin-left: 100px;
  top: 330px;
  transition: all .6s ease;
}
.MidContentName{
  position: relative;
  overflow: hidden;
  /* letter-spacing: 20px; */
  font-size: 70px;
  /* background-color: rgb(178, 219, 137); */
  width: 800px;
  height: 100px;
  transition: all .6s ease;
  display: flex;
  flex-direction: row;
}
.MidContentNameLetter{
  position: relative;
  /* background-color: brown; */
  margin-right: 16px;
  bottom: 0px;
  transition: bottom .1s ease;
  cursor: default;
  padding-bottom: 0px;

}
.MidContentNameLetter:hover{
  bottom: 10px;
  color: transparent;
}
.MidContentProffesion{
  font-size: 18px;
  /* background-color:rgb(201, 152, 152); */
  width: 800px;
  height: 30px;
  cursor: default;
  transition: all .6s ease;
}
.MidContentAbout{
  position: relative;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
  background-image: linear-gradient(to right, red ,rgb(0, 162, 255));
  width: 120px;
  height: 32px;
  top: 20px;
  padding-top: 0px;
  cursor: pointer;
  transition: all .6s ease;
}
/* .AboutWrapper{
  background-color: red;
  position: relative;
  float: right;
  width: 800px;
  height: 500px;
  bottom:300px;
} */
.ProjectsSuperWrapper{
  padding: 10px;
  padding-top: 20px;
  position: relative;
  bottom: 40px;
  background-color: rgb(12, 10,15);
  display: none;
  overflow-y: scroll;
  height: 100vh;
  transition: all 1s ease;
}
.EducationSuperWrapper{
  display: none;
  /* background-color: aqua; */
  position: relative;
  top: -30px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.ContactSuperWrapper{
  /* background-color: thistle; */
  display: none;
  position: relative;
  top: -30px;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}
.AboutMeSuperWrapper{
  display: none;
  position: relative;
  top: -30px;
  /* background-color: blue; */
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}
@media(max-width:1400px){
  .ProjectsSuperWrapper{
    padding-top: 40px;
  }
  .RightHeaderWrapper{
    margin-left: 94vw;
  }
}
/* __________________________________________________________________ */
/* __________________________1000______________________________ */
/* __________________________________________________________________ */
@media(max-width:1000px){
  .RightHeaderWrapper{
    margin-left: 92vw;
  }
  .MidContent{
    width: 600px;
    height: 160px;
  }
  .MidContentName{
    font-size: 40px;
    height: 60px;
    width: 600px;
  }
  .MidContentNameLetter{
    margin-right: 12px;
  }
  .MidContentNameLetter:hover{
    bottom: 6px;
  }
  .MidContentProffesion{
    width: 600px;
  }
  .SpinAnimWrapper{
    margin-left: 100px;
  }
  .EducationSuperWrapper{
    overflow-y: scroll;
  }
}
/* __________________________________________________________________ */
/* ___________________________750_________________________________ */
/* __________________________________________________________________ */
@media(max-width:750px){
  .RightHeaderWrapper{
    margin-left: 91vw;
  }
  .MidContent{
    width: 450px;
    margin-left: 50px;
    top: 200px;
  }
  .MidContentName{
    font-size: 24px;
    letter-spacing: 15px;
    height: 50px;
    width: 450px;
  }
  .MidContentNameLetter{
    margin-right: 2px;
  }
  .MidContentNameLetter:hover{
    bottom: 5px;
  }
  .MidContentProffesion{
    font-size: 16px;
    width: 450px;
  }
  .MidContentAbout{
    padding-top: 1px;
  }
  .HeaderWrapper{
    padding-right: 8%;
    top: 25px;
  }
  .HeaderItem{
  padding-left: 20px;
  padding-right: 20px;
  }
  .HeaderLineThingWrapper{
    top: 25px;
  }
  .HeaderLineThing{
    margin-right: 213px;
  }
}
/* __________________________________________________________________ */
/* ____________________________500_________________________________ */
/* __________________________________________________________________ */
@media(max-width:500px){
  .RightHeaderWrapper{
    margin-top: 20vh;
  }
  .RightHeaderWrapper{
    margin-left: 85vw;
  }
  .GitHubButton{
  font-size: 12px;
  }
  .LinkedInButton{
    font-size: 12px;
  }
  .MidContent{
    width: 380px;
    top: 150px;
    height: 120px;
    
  }
  .MidContentName{
    font-size: 26px;
    letter-spacing: 12px;
    height: 40px;
    width: 300px;
  }
  .MidContentNameLetter{
    margin-right: 0px;
  }
  .MidContentProffesion{
    width: 380px;
  }
  .MidContentAbout{
    top: 10px;
    font-size: 16px;
    width: 100px;
    height: 30px;
    padding-top: 5px;
  }
}
@media(max-width:499px){
  .Logo{
    top: 18px;
    left: 8px;
    width: 35px;
    height: 35px;
  }
    .HeaderItem{
    font-size: 12px;
    padding-left: 7px;
    padding-right: 7px;
  }
  .HeaderLineThing{
    /* height: 1px; */
    /* width: 3px; */
  }
  .RightHeaderWrapper{
    margin-top: 10vh;
  }
  .MidContent{
    width: 380px;
    top: 100px;
    height: 120px;
    margin-left: 10px;
  }
  .MidContentName{
    font-size: 22px;
    letter-spacing: 12px;
    height: 40px;
    width: 270px;
  }
  .MidContentNameLetter{
    margin-right: 0px;
  }
}
/* __________________________________________________________________ */
/* _____________________________Height 700_______________________________ */
/* __________________________________________________________________ */
@media(max-height: 700px) and (min-width: 550px){
    .MidContent{
      top: 200px;
    }
  }
/* __________________________________________________________________ */
/* _____________________________Height 400_______________________________ */
/* __________________________________________________________________ */
@media(max-height: 400px){
  .MidContent{
    top: 100px;
  }
}
.TransitionSuperWrapper{
    position: absolute;
    z-index: 98;
}
.LoadingWrapper{
    z-index: 99;
    position: absolute;
    /* background-color: rgb(255, 25, 0); */
    width: 100%;
    margin-left: -8px;
}
.LoadingThing{
    position: relative;
    margin: auto;
    top: 40vh;
    left: 60vw;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    animation-name: loading;
    animation-duration: 1s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
}
@keyframes loading{
    0%{left: 0vw;top: -50vh;}
    50%{left: 0vw;top: 40vh;}
    100%{left: 60vw;top: 40vh;}
}
.TransitionMainWrapper{
  border-top: solid 5px rgb(255, 255, 255);
}
@media(orientation: landscape){
    .TransitionMainWrapper{
    position: relative;
    right: 70vw;
    bottom: 70vw;
    width: 200vw;
    height: 200vw;
    background-color: rgb(12, 10, 15);
    transform: rotateZ(135deg);
    animation-name: move;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
    @keyframes move {
    0%{bottom:70vw;right: 70vw;}
    100%{bottom: 180vw;right: 180vw;}
  }
}

@media(orientation: portrait){
    .TransitionMainWrapper{
    position: relative;
    right: 70vh;
    bottom: 70vh;
    width: 200vh;
    height: 200vh;
    background-color: rgb(12, 10, 15);
    transform: rotateZ(135deg);
    animation-name: move;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
    @keyframes move {
    0%{bottom:70vh;right: 70vh;}
    100%{bottom: 180vh;right: 180vh;}
  }
}
a{
  text-decoration: none;
  color: white;
}
.ProjectsMainAnim {
    z-index: -2;
    position: sticky;
    top: 1vh;
    left: 1vw;
    background-color: rgb(12, 10, 15);
    width: 1px;
    height: 5px;
    /* border-radius: 50%; */
    animation: ProjectsMainAnim;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

}
.ProjectsMainAnim2{
    z-index: -2;
    position: sticky;
    top: 1vh;
    left: 1vw;
    background-color: rgb(12, 10, 15);
    width: 1px;
    height: 5px;
    /* border-radius: 50%; */
    animation: ProjectsMainAnim;
    animation-duration: 20s;
    animation-delay: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes ProjectsMainAnim {
  25% {
      top: 0vh;
      left: 96vw;
      /* border-radius: 0; */
      background-color: transparent;
  }
  37%{
    background-color:  rgb(255, 255, 255);
  }
  50% {
      top: 96vh;
      left: 96vw;
      /* border-radius: 50%; */
      background-color: transparent;
  }
  
  75% {
      top: 96vh;
      left: 1vw;
      /* border-radius: 0; */
      background-color: transparent;
  }
  88%{
     background-color: rgb(255, 255, 255);
  }

  100% {
      top: 0vh;
      left: 1vw;
      /* border-radius: 50%; */
      background-color: transparent;
  }
}