.ContactSectionWrapper{
    /* background-color: rgb(247, 247, 247); */
    height:600px; 
    position: relative;
    top: 80px;
    transition: all .4s ease;
}
.ContactFormWrapper{
    /* background-color: red; */
    width: 800px;
    height: 350px;
    margin: auto;
    margin-top: 100px;
    transition: all .4s ease;
}
.EmailInputWrapper{
    /* background-color: royalblue; */
    width: 400px;
    height: 60px;
    float: left;
    transition: all .4s ease;
}
.EmailInput{
    background-color: rgb(43, 43, 43);
    width: 290px;
    height: 40px;
    margin-left: 100px;
    border-style: none;
    outline-style: none;
    color: white;
    text-align: center;
    transition: all .4s ease;
    font-family: 'Baloo Tamma 2', cursive;
}
.EmailInput:hover{
    background-color: rgb(36, 36, 36);
}
.ReasonInputWrapper{
    /* background-color: seagreen; */
    width: 400px;
    height: 60px;
    float: right;
    transition: all .4s ease;
}
.ReasonInput{
    background-color: rgb(43, 43, 43);
    width: 290px;
    height: 40px;
    margin-left: 10px;
    border-style: none;
    outline-style: none;
    color: white;
    text-align: center;
    transition: all .4s ease;
    font-family: 'Baloo Tamma 2', cursive;
}
.ReasonInput:hover{
    background-color: rgb(36, 36, 36);
}
.MessageInputWrapper{
    /* background-color: sienna; */
    width: 800px;
    height: 300px;
    transition: all .4s ease;
}
.MessageInput{
    background-color: rgb(43, 43, 43);
    position: relative;
    width: 585px;
    height: 200px;
    margin-left: 100px;
    margin-top: 30px;
    padding: 10px;
    resize: none;
    border-style: none;
    outline-style: none;
    color: white;
    transition: all .4s ease;
    font-family: 'Baloo Tamma 2', cursive;
    letter-spacing: 1px;
}
.MessageInput:hover{
    background-color: rgb(36, 36, 36);
}
.MessageCount{
    /* background-color: thistle; */
    width: 90px;
    margin-left: 80%;
    color: rgb(214, 214, 214);
    transition: all .4s ease;
}
.ContactSubmitWrapper{
    position: relative;
    width: 100px;
    height: 30px;
    margin-top: 20px;
    margin-left: 100px;
    /* background-color: thistle; */
    transition: all .4s ease;
    bottom: 0px;
}
.ContactSubmit{
    width: 90px;
    height: 28px;
    border-style: none;
    outline-style: none;
    font-size: 18px;
    background-color: rgb(72, 43, 100);
    background-image: linear-gradient(90deg, red, rgb(0, 162, 255));
    font-family: 'Baloo Tamma 2', cursive;
    color: white;
    letter-spacing: 2px;
    transition: all .4s ease;
    cursor: pointer;
}
/*  */
/*-------------------------------------------------------------------------------------------------------------------------------------------------------------*/
/*  */
@media(max-width: 800px){
.ContactFormWrapper{
    width: 500px;
    margin-top: 60px;
}
.EmailInputWrapper{
    width: 250px;
}
.EmailInput{
    width: 200px;
    margin-left: 20px;
}
.ReasonInputWrapper{
    width: 250px;
}
.ReasonInput{
    width: 200px;
    margin-left:25px;
}
.MessageInputWrapper{
    width: 500px;  
}
.MessageInput{
    width: 440px; 
    margin-left: 20px;
}
.ContactSubmitWrapper{
    margin-left: 21px;
}
}
/*  */
/*----------------------------------------------------------------------------------------------------------------------------*/
/*  */
@media(max-width: 590px){
.ContactFormWrapper{
    /* background-color: teal; */
    width: 300px;
    margin-top: 0px;
}
.EmailInputWrapper{
    /* background-color: thistle; */
    width: 250px;
    float: none;
    margin: auto;
}
.EmailInput{
    width: 200px;
    height: 30px;
    margin-left: 25px;
}
.ReasonInputWrapper{
    /* background-color: tomato; */
    width: 250px;
    float: none;
    margin: auto;
}
.ReasonInput{
    width: 200px;
    height: 30px;
    margin-left: 25px;
}
.MessageInputWrapper{
    width: 300px;  
}
.MessageInput{
    width: 280px;
    height: 150px; 
    margin-left: 0px;
    margin-top: 10px;
}
.ContactSubmitWrapper{
    bottom: 130px;
    margin-left: 0px;
}
}
/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media(max-width: 499px){
    .MessageInput{
        width: 210px; 
        margin-left: 35px;
    }
    .ContactSubmitWrapper{
        margin-left: 36px;
    }
}